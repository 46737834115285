<template>
  <div class="wrapper">
    <div class="card-container">
      <b-card
        v-for="item in list"
        :key="item.i"
        style="max-width: 20rem"
        class="mb-2"
        @click="go(item.id)"
      >
        <img src="../../../../assets/icon/new-features.svg" alt="" />
        <div class="text">
          <div class="how">
            <span>{{ item.judulFitur }}</span>
          </div>
          <!-- <div class="something">
            <span>to add a project</span>
          </div> -->
        </div>
      </b-card>
    </div>

    <div class="see-more">
      <div class="button" @click="$router.push('/fitur/' + 0)">
        <span
          >see more
          <img src="../../../../assets/icon/right-arrow.svg" alt="" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
export default {
  props: ["idFitur"],
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let vm = this;
      let get = await axios.get("fitur/list", {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      if (get.data.message == "sukses") {
        let x = [];
        get.data.data.sort((a, b) => {
          return b.id - a.id;
        });
        get.data.data.map((item, id) => {
          if (id <= 3) {
            x.push(item);
          }
        });
        vm.list = x;
      }
    },
    go(id) {
      this.$router.push("/fitur/" + id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  height: 100%;
  width: 100%;
  .card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 80%;
    width: 100%;
    justify-content: space-evenly;
    .card {
      width: 40%;
      height: 150px;
      margin: 0 1%;
      cursor: pointer;
      .text {
        margin-top: 10%;
        .how {
          font-family: "nunito", sans-serif;
          text-transform: capitalize;
          font-weight: 600;
          font-size: 18px;
          color: map-get($color, "black");
        }
        .something {
          font-weight: 700;
          font-family: "nunito", sans-serif;
          color: map-get($color, "black");
        }
      }
    }
  }
  .see-more {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 20%;
      font-family: "nunito", sans-serif;
      color: map-get($color, "blue");
      font-weight: 600;
      text-transform: capitalize;
      cursor: pointer;
      img {
        width: 20%;
      }
    }
  }
}
</style>
