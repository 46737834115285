<template>
  <div class="wrapper">
    <div class="filter">
      <div class="button-wrapper">
        <b-button disabled pill v-if="loadingInvitation == true"
          >refresh table</b-button
        >
        <b-button pill v-else @click="getData">refresh table</b-button>
      </div>
      <div class="filter-wrapper">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <div class="table">
      <b-table
        hover
        striped
        :filter="filter"
        :items="invitationsTable"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
        style="
          text-align: center;
          font-family: 'nunito', sans-serif;
          min-height: 360px;
        "
      >
        <!-- <template #cell(namaKAP)="data"
          ><a @click="accept(data.item.clientid)">{{ data.value }}</a>
        </template> -->
        <template #cell(actions)="data">
          <b-button
            pill
            @click="accept(data.item)"
            style="
              background-color: #327ff2;
              font-weight: 700;
              border: none;
              font-family: 'nunito';
              margin-right: 2%;
              padding: 4px 12px;
              font-size: 14px;
            "
          >
            <span>Terima</span>
          </b-button>

          <b-button
            pill
            v-if="loading == true"
            disabled
            style="
              background-color: #e0544e;
              border: none;
              font-family: 'nunito';
              font-weight: 700;
              margin-left: 2%;
              padding: 4px 12px;
              font-size: 14px;
            "
          >
            <span>Tolak</span>
          </b-button>
          <b-button
            pill
            v-else
            @click="reject(data.item)"
            style="
              background-color: #e0544e;
              border: none;
              font-family: 'nunito';
              font-weight: 700;
              margin-left: 2%;
              padding: 4px 12px;
              font-size: 14px;
            "
          >
            <span>Tolak</span>
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="paginate">
      <b-pagination
        v-model="currentPage"
        :total-rows="invitationsTable.length"
        style="width: 50%; font-family: 'nunito', sans-serif"
        :per-page="perPage"
        size="sm"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "@/ip_config";

export default {
  data() {
    return {
      perPage: 4,
      currentPage: 1,
      fields: [
        {
          key: "nama",
          label: "KAP / Client / Project",
        },
        {
          key: "jabatan",
          label: "Peran",
        },
        {
          key: "actions",
          label: "Respon",
        },
      ],
      filter: null,
      loading: false,
    };
  },

  computed: {
    ...mapState("apiInvite", ["invitationsTable", "loadingInvitation"]),
  },

  mounted() {
    this.$store.dispatch("apiInvite/getInvite");
  },

  methods: {
    accept(item) {
      let role = item.jabatan;

      if (role == "manager") {
        axios.post(
          "users/acceptKAP",
          {
            id: item.id,
            KAPId: item.KAPId,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        this.$store.dispatch("apiInvite/getInvite");
        this.$store.dispatch("apiListKAP/getKAP");
      } else if (role == "supervisor") {
        axios.post(
          "users/acceptClient",
          {
            id: item.id,
            KAPId: item.kapid,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        this.$store.dispatch("apiInvite/getInvite");
        this.$store.dispatch("apiListKAP/getKAP");
      } else if (role == "auditor") {
        axios.post(
          "users/acceptProject",
          {
            id: item.id,
            KAPId: item.kapid,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        this.$store.dispatch("apiInvite/getInvite");
        this.$store.dispatch("apiListKAP/getKAP");
      }
    },

    reject(item) {
      let vm = this;
      vm.loading = true;
      let role = item.jabatan;

      if (role == "manager") {
        axios
          .post(
            "users/rejectKAP/" + item.id,
            {},
            {
              headers: {
                token: localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            vm.loading = false;
          });
      } else if (role == "supervisor") {
        axios
          .post(
            "users/rejectClient/" + item.id,
            {},
            {
              headers: {
                token: localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            vm.loading = false;
          });
      } else if (role == "auditor") {
        axios
          .post(
            "users/rejectProject/" + item.id,
            {},
            {
              headers: {
                token: localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            vm.loading = false;
          });
      }
    },

    getData() {
      this.$store.dispatch("apiInvite/getInvite");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  width: 100%;
  .button-wrapper {
    .btn {
      background-color: map-get($color, "blue");
      font-family: "nunito", sans-serif;
      font-weight: 700;
      text-transform: capitalize;
      border: none;
      font-size: 14px;
      border-radius: 50px;
    }
  }
  .filter {
    margin-bottom: 2%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .filter-wrapper {
      width: 60%;
    }
  }
  .table {
    width: 100%;
  }
  .paginate {
    position: static;
    transform: translate(40%, -10%);
  }
}
</style>
