<template>
  <div>
    <nav_bar />
    <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <b-container fluid style="padding-top: 115px; padding-bottom: 30px">
        <b-row style="padding: 0 1%">
          <b-col md="12" lg="12" xl="7">
            <div class="left-box">
              <div class="top">
                <div class="trio" v-if="loadingKAP == false">
                  <b-button
                    pill
                    :class="{ tabActive: currentTab == 1 }"
                    @click="selectTab(1)"
                    >jumlah kantor
                    <span class="angka">{{ stateKAP.length }}</span></b-button
                  >
                  <b-button
                    pill
                    :class="{ tabActive: currentTab == 2 }"
                    @click="selectTab(2)"
                    >undangan
                    <span class="angka">{{ inviteLength }}</span></b-button
                  >
                  <b-button class="tambah" @click="addModals" pill
                    >tambah kantor
                    <img src="../../../assets/icon/plus.svg" alt=""
                  /></b-button>
                </div>

                <div class="trio" v-else>
                  <b-skeleton
                    class="skeleton"
                    v-for="s in 3"
                    :key="s"
                    animation="throb"
                  ></b-skeleton>
                </div>

                <b-dropdown
                  id="dropdown-1"
                  text="Ubah Menu"
                  class="m-md-2"
                  variant="outline-primary"
                  style="font-weight: 300"
                >
                  <b-dropdown-item
                    style="font-family; 'nunito', sans-serif"
                    @click="selectView(1)"
                    >Card List</b-dropdown-item
                  >
                  <b-dropdown-item
                    style="font-family; 'nunito', sans-serif"
                    @click="selectView(2)"
                    >Table List</b-dropdown-item
                  >
                </b-dropdown>
              </div>

              <div
                class="left-box1"
                v-if="loadingKAP == true"
                style="display: flex; flex-wrap: wrap"
              >
                <b-skeleton
                  class="left-box-loading"
                  v-for="i in 6"
                  animation="throb"
                  :key="i"
                ></b-skeleton>
              </div>

              <div class="content" v-else>
                <div class="card1" v-if="currentTab == 1">
                  <div>
                    <listkap v-if="currentView == 1" />
                    <table_list_kap v-else />
                  </div>
                </div>

                <div
                  class="card2"
                  v-else-if="currentTab == 2 && currentView == 1"
                >
                  <invitation />
                </div>

                <div
                  class="card2"
                  v-else-if="currentTab == 2 && currentView == 2"
                >
                  <table_invitation />
                </div>

                <b-modal
                  hide-footer
                  hide-header
                  centered
                  ref="modal-add"
                  size="lg"
                >
                  <div
                    class="contentss"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-direction: column;
                      padding: 1% 2%;
                    "
                  >
                    <div class="title" style="width: 100%; text-align: start">
                      <span
                        class="title"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 28px;
                          font-weight: 700;
                          text-transform: capitalize;
                        "
                        >tambah kantor</span
                      >
                    </div>

                    <div class="error">
                      <span
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 18px;
                          font-weight: 700;
                          color: #e0544e;
                          text-transform: capitalize;
                        "
                        >{{ pesan }}</span
                      >
                    </div>

                    <div class="form" style="width: 95%; margin-top: 4%">
                      <b-form-group
                        label="Nama Kantor"
                        label-for="input-formatter"
                        class="mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 20px;
                          font-weight: 300;
                        "
                      >
                        <b-form-input
                          id="input-formatter"
                          placeholder="Nama Kantor"
                          style="border-radius: 10px"
                          size="lg"
                          type="text"
                          v-model="namaKAP"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Nomor Ijin"
                        label-for="input-formatter"
                        class="mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 20px;
                          font-weight: 300;
                          margin-top: 2%;
                        "
                      >
                        <b-form-input
                          v-model="noIjin"
                          id="input-formatter"
                          placeholder="Nomor Ijin"
                          style="border-radius: 10px"
                          size="lg"
                          type="number"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Alamat KAP"
                        label-for="input-formatter"
                        class="mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 20px;
                          font-weight: 300;
                          margin-top: 2%;
                        "
                      >
                        <b-form-input
                          v-model="alamatKAP"
                          id="input-formatter"
                          placeholder="Alamat KAP"
                          style="border-radius: 10px"
                          size="lg"
                          type="text"
                        ></b-form-input
                      ></b-form-group>

                      <b-form-group
                        label="Nomor Telepon"
                        label-for="input-formatter"
                        class="mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 20px;
                          font-weight: 300;
                          margin-top: 2%;
                        "
                      >
                        <b-form-input
                          v-model="telfon"
                          id="input-formatter"
                          placeholder="Nomor Telepon"
                          style="border-radius: 10px"
                          size="lg"
                          type="number"
                        ></b-form-input
                      ></b-form-group>

                      <b-form-group
                        label="Email KAP"
                        label-for="input-formatter"
                        class="mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 20px;
                          font-weight: 300;
                          margin-top: 2%;
                        "
                      >
                        <b-form-input
                          v-model="emailKAP"
                          id="input-formatter"
                          placeholder="Email KAP"
                          style="border-radius: 10px"
                          size="lg"
                          type="text"
                        ></b-form-input
                      ></b-form-group>

                      <b-form-group
                        label="Website KAP"
                        label-for="input-formatter"
                        class="mb-0"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 20px;
                          font-weight: 300;
                          margin-top: 2%;
                        "
                      >
                        <b-form-input
                          v-model="website"
                          id="input-formatter"
                          placeholder="Website KAP"
                          style="border-radius: 10px"
                          size="lg"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div
                    class="wrapper"
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      padding: 0 2%;
                    "
                  >
                    <div
                      class="button-wrapper"
                      style="
                        width: 95%;
                        display: flex;
                        justify-content: flex-end;
                      "
                    >
                      <b-button
                        @click="doAdd"
                        v-if="loading == false && noIjin != ''"
                        style="
                          background-color: #327ff2;
                          color: #fff;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          border: none;
                          margin-top: 20px;
                          height: 40px;
                          width: 1000px;
                        "
                        >Tambah KAP</b-button
                      >
                      <b-button
                        disabled
                        v-else-if="loading == true"
                        block
                        style="
                          background-color: #327ff2;
                          color: #fff;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          border: none;
                          margin-top: 20px;
                          height: 40px;
                        "
                        ><white-spinner
                      /></b-button>
                      <b-button
                        disabled
                        v-else
                        block
                        style="
                          background-color: #327ff2;
                          color: #fff;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          border: none;
                          margin-top: 20px;
                          height: 40px;
                        "
                        >Tambah KAP</b-button
                      >
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
          </b-col>

          <b-col md="12" lg="12" xl="5">
            <div class="right-box">
              <donut />
            </div>
          </b-col>
        </b-row>

        <b-row style="margin-top: 2%; padding: 0 1%">
          <b-col md="12" lg="12" xl="12">
            <div class="ads">
              <hooper
                :itemsToShow="3.2"
                :autoPlay="true"
                :playSpeed="4000"
                :centerMode="true"
                :wheelControl="false"
                :infiniteScroll="true"
              >
                <slide v-for="data in iklan" :key="data.i">
                  <center>
                    <a :href="data.link_URL" target="blank">
                      <img
                        style="
                          max-width: 90%;
                          min-width: 90%;
                          min-height: 200px;
                          max-height: 200px;
                          border-radius: 7px;
                        "
                        :src="data.image"
                        alt=""
                      />
                    </a>
                  </center>
                </slide>
                <hooper-navigation slot="hooper-addons"></hooper-navigation>
              </hooper>
            </div>
          </b-col>
        </b-row>

        <b-row style="margin-top: 2%; padding: 0 1%">
          <b-col md="12" lg="12" xl="7">
            <div class="left-box2">
              <div class="title-box">
                <span
                  style="font-size: 28px; margin-left: 10px; font-weight: 600"
                  >Grafik</span
                >
                <b-form-group style="width: 45%">
                  Masukan Tahun
                  <b-form-input
                    v-model="tahun"
                    type="number"
                    valueType="format"
                    style="width: 100%"
                  ></b-form-input>
                </b-form-group>
              </div>
              <linear :toChild="tahun" @tahun="fromChild($event)" />
            </div>
          </b-col>
          <b-col md="12" lg="12" xl="5">
            <div class="right-box2">
              <div class="title-box">
                <span
                  style="
                    font-size: 28px;
                    margin-left: 10px;
                    font-weight: 600;
                    font-family: 'nunito', sans-serif;
                    text-transform: capitalize;
                  "
                  >new features</span
                >
              </div>
              <new_features />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="d-sm-block d-md-none d-lg-none d-xl-none">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12">
            <div
              style="
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h4 class="text-center">
                <strong>Does not support mobile version</strong>
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import nav_bar from "@/components/nav_bar.vue";
import axios from "@/ip_config.js";
import WhiteSpinner from "@/components/spinner/white-spinner.vue";
import Donut from "@/components/chart/donut.vue";
import Invitation from "./components/InvitationCards.vue";
import new_features from "./components/NewFeatures.vue";
import Linear from "@/components/chart/linear.vue";
import { mapState } from "vuex";
import Listkap from "./components/KAPCards.vue";
import Table_list_kap from "./components/KAPTable.vue";
import BigBlueSpinner from "@/components/spinner/big-blue-spinner.vue";
import Table_invitation from "./components/InvitationTable.vue";
import ip_ads from "@/ip_ads";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
import List from "./components/NewFeatures.vue";

export default {
  components: {
    nav_bar,
    WhiteSpinner,
    Donut,
    Invitation,
    Linear,
    new_features,
    Listkap,
    Table_list_kap,
    BigBlueSpinner,
    Table_invitation,
    Hooper,
    Slide,
    HooperNavigation,
    List,
  },
  computed: {
    ...mapState("apiListKAP", ["stateKAP", "loadingKAP"]),
    ...mapState("apiInvite", ["inviteLength"]),
  },
  data() {
    return {
      currentTab: 1,
      currentView: 1,
      namaKAP: "",
      noIjin: "",
      alamatKAP: "",
      telfon: "",
      emailKAP: "",
      website: "",
      id: "",
      pesan: "",
      listKAP: [],
      lengthKAP: [],
      loading: false,
      iklan: [],
      tahun: "",
    };
  },
  beforeMount() {
    this.$store.dispatch("apiUser/getUser");
    localStorage.removeItem("idClient");
    localStorage.removeItem("idKAP");
    localStorage.removeItem("role");
    localStorage.removeItem("namaClient");
    localStorage.removeItem("idProject");
    localStorage.removeItem("idThread");
    localStorage.removeItem("tahunProject");
    ip_ads.get("promotion").then((res) => {
      if (res.data.meta.status == "success") {
        this.iklan = res.data.data.promotion;
      }
    });
  },
  mounted() {
    this.$store.dispatch("apiListKAP/getKAP");
    this.$store.dispatch("apiInvite/getInvite");
  },
  methods: {
    fromChild(e) {
      this.tahun = e;
    },
    get(e) {
      this.idFitur = e;
      this.$router.push("/fitur");
    },
    selectTab(selected) {
      let vm = this;
      vm.currentTab = selected;
    },
    selectView(selected) {
      let vm = this;
      vm.currentView = selected;
    },
    doRefresh() {
      this.getData();
    },
    addModals() {
      this.$refs["modal-add"].show();
    },
    closeModal() {
      this.$refs["modal-add"].hide();
    },
    doAdd() {
      let vm = this;
      vm.loading = true;
      axios
        .post(
          "KAP/register",
          {
            namaKAP: vm.namaKAP,
            noIjin: vm.noIjin,
            telfon: vm.telfon,
            emailKAP: vm.emailKAP,
            website: vm.website,
            alamatKAP: vm.alamatKAP,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          vm.loading = false;
          if (res.data.message == "data sudah ada") {
            vm.pesan = "KAP sudah terdaftar";
          } else {
            this.$refs["modal-add"].hide();
            vm.pesan = "";
            vm.namaKAP = "";
            vm.noIjin = "";
            vm.telfon = "";
            vm.emailKAP = "";
            vm.website = "";
            vm.alamatKAP = "";
            this.$store.dispatch("apiListKAP/getKAP");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);
.container-fluid {
  background-color: map-get($color, "blue");
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../assets/vector/Background.png");
  background-size: auto;
  background-repeat: repeat;
}
.left-box2 {
  background-color: #fff !important;
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  height: 500px;
  font-family: "nunito", sans-serif;
  .title-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
.right-box2 {
  background-color: #fff !important;
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  height: 500px;
}
.left-box-loading {
  width: 180px;
  padding: 2%;
  margin-left: 10%;
  margin-top: 5%;
  border-radius: 10px;
  height: 172px;
}
.left-box {
  background-color: #fff !important;
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  position: relative;
  .title-box {
    font-family: "nunito", sans-serif;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 2%;
  }
  .top {
    padding: 0 4%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .trio {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      .btn {
        background-color: transparent;
        color: map-get($color, "black");
        border: none;
        text-align: center;
        display: flex;
        align-items: center;
        font-weight: 300;
        height: 40px;
        text-transform: capitalize;
        .angka {
          margin-left: 8px;
          background-color: #e8f3fe;
          border-radius: 50px;
          padding: 0.5px 7.5px;
          font-weight: 600;
          color: map-get($color, "blue");
        }
        img {
          margin-left: 8px;
          background-color: #e8f3fe;
          border-radius: 50px;
          padding: 5px;
        }
      }
      .tambah {
        background-color: map-get($color, "blue");
        color: map-get($color, "white2");
      }
      .btn:hover {
        background-color: #e8f3fe;
        color: map-get($color, "blue");
        border: none;
        font-weight: 300;
        text-align: center;
        display: flex;
        text-transform: capitalize;
        align-items: center;
        span {
          margin-left: 8px;
          background-color: #fff;
          border-radius: 50px;
          padding: 0.5px 7.5px;
        }
        img {
          margin-left: 8px;
          background-color: #fff;
          border-radius: 50px;
          padding: 5px;
        }
      }
      .tabActive {
        background-color: #e8f3fe;
        color: map-get($color, "blue");
        border: none;
        font-weight: 300;
        text-align: center;
        display: flex;
        text-transform: capitalize;
        align-items: center;
        span {
          margin-left: 8px;
          background-color: #fff !important;
        }
        img {
          margin-left: 8px;
          background-color: #fff;
          border-radius: 50px;
          padding: 5px;
        }
      }
      .skeleton {
        width: 150px;
        height: 40px;
        border-radius: 50px;
      }
    }
    #dropdown-1 {
      .btn {
        background-color: transparent !important;
      }
    }
  }
  .content {
    padding: 2% 4%;
    width: 100%;
    height: 100%;
    .card1 {
      width: 100%;
      height: 100%;
      position: relative;
      .cards {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .card {
          background-color: map-get($color, "white");
          margin: 2%;
          width: 200px;
          height: 172px;
          cursor: pointer !important;
          .card-body {
            padding: 6%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .card-title {
            font-family: "nunito", sans-serif;
            font-weight: 700;
            color: map-get($color, "black");
            text-transform: uppercase;
          }
          .card-text {
            font-family: "nunito", sans-serif;
            color: map-get($color, "black");
            font-weight: 300;
            .details {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .role {
                background-color: #e8f3fe;
                padding: 0 10px;
                text-transform: capitalize;
                border-radius: 50px;
                color: map-get($color, "blue");
              }
              .tanggal {
                display: flex;
                flex-direction: column;
                font-size: 14px;
              }
            }
          }
        }
      }
      .button-wrapper2 {
        display: flex;
        justify-content: flex-end;
        width: 96%;
        margin-top: 4%;
        .btn {
          width: 30%;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background-color: transparent;
          border: none;
          font-family: "nunito", sans-serif;
          font-size: 16px;
          color: map-get($color, "blue");
          img {
            width: 8%;
            margin-left: 4%;
          }
        }
      }
    }
    .card2 {
      width: 100%;
    }
  }
}
.right-box {
  background-color: map-get($color, "white2");
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  height: 100%;

  .title-box {
    font-family: "nunito", sans-serif;
    font-size: 28px;
    font-weight: 700;
    text-transform: capitalize;
    margin-left: 4%;
    margin-top: 2%;
  }
}
.overlay {
  height: 200px;
  width: 90%;
  position: absolute;
  z-index: 4;
  inset: 0;
  margin: auto;
}
.ads {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .wrapper-ads {
    max-width: 32%;
    min-width: 32%;
    border-radius: 10px;
    background-color: map-get($color, "white2");
    overflow: hidden;
    .images {
      width: 100%;
      max-height: 100%;
      img {
        max-width: 100%;
        min-height: 100%;
      }
    }
  }
}
</style>
