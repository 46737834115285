<template>
  <div class="wrapper">
    <div class="cards" v-if="list.length > 0">
      <b-card
        class="mb-2"
        v-for="(data, i) in paginatedItems"
        :key="i"
        @click="viewKAP(data.id, data.theRole)"
        @click.right="viewInNewTab(data.id, data.theRole)"
        @click.middle="viewInNewTab(data.id, data.theRole)"
        @contextmenu.prevent
      >
        <b-card-text v-b-tooltip.hover :title="data.namaKAP">
          <h4
            style="
              font-weight: 700;
              text-transform: capitalize;
              font-size: 20px;
            "
            id="cardss"
          >
            {{ data.namaKAP1 }}
          </h4>
          <div class="details">
            <span class="role">{{ data.theRole }}</span>
            <div class="tanggal">
              <span class="join">joined on</span>
              <span class="date">{{ data.updatedAt }}</span>
            </div>
          </div>
        </b-card-text>
      </b-card>
    </div>
    <div class="empty" v-else-if="list.length == 0">
      <span>belum ada daftar KAP</span>
    </div>
    <div>
      <div class="pagination" v-if="stateKAP.length > 6">
        <b-pagination
          align="center"
          @change="onPageChanged"
          :total-rows="stateKAP.length"
          :per-page="perPage"
          v-model="currentPage"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("apiListKAP", ["stateKAP"]),
  },

  data() {
    return {
      list: [],
      paginatedItems: [],
      currentPage: 1,
      perPage: 6,
      namanya: "",
    };
  },

  mounted() {
    let vm = this;
    vm.list = vm.stateKAP;
    vm.paginatedItems = vm.stateKAP;
    this.paginate(vm.perPage, 0);
  },

  methods: {
    viewKAP(id, theRole) {
      localStorage.setItem("role", theRole);
      this.$router.push("/profile_kap/" + id);
    },

    viewInNewTab(id, theRole){
      localStorage.setItem("role", theRole);
      window.open("https://online.kapmobile.id/#/profile_kap/" + id, '');
    },

    paginate(pageSize, pageNumber) {
      let vm = this;
      let itemsToParse = vm.list;

      vm.paginatedItems = itemsToParse.slice(
        pageNumber * pageSize,
        (pageNumber + 1) * pageSize
      );
    },
    onPageChanged(page) {
      let vm = this;
      this.paginate(vm.perPage, page - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  width: 100%;
  height: 460px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  .cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // transform: translateX(18px);
    .card {
      background-color: map-get($color, "white");
      margin-left: 2.5%;
      margin-top: 2%;
      width: 30%;
      height: 172px;
      cursor: pointer !important;
      .card-body {
        padding: 8%;
      }
      .card-title {
        font-family: "nunito", sans-serif;
        font-weight: 700;
        color: map-get($color, "black");
        height: 50%;
        text-transform: capitalize;
      }
      .card-text {
        font-family: "nunito", sans-serif;
        color: map-get($color, "black");
        font-weight: 300;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .details {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .role {
            background-color: #e8f3fe;
            padding: 0 10px;
            text-transform: capitalize;
            border-radius: 50px;
            font-size: 14px;
            font-weight: 700;
            margin-right: 2%;
            color: map-get($color, "blue");
          }
          .tanggal {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            text-transform: capitalize;
            margin-left: 2%;
          }
        }
      }
    }

    .pagination {
      width: 100%;
      padding: 1% 0;
    }
  }

  .empty {
    font-family: "nunito", sans-serif;
    color: map-get($color, "black");
    text-transform: capitalize;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    font-size: 28px;
  }

  .button-wrapper2 {
    display: flex;
    justify-content: flex-end;
    width: 98%;
    .btn {
      width: 30%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: transparent;
      border: none;
      font-family: "nunito", sans-serif;
      color: map-get($color, "blue");
      img {
        width: 10%;
        margin-left: 10%;
      }
    }
  }
}
</style>
