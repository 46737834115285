<template>
  <div class="wrapper">
    <div class="card-wrapper">
      <b-card class="mb-2" v-for="(data, i) in list" :key="i" id="itemList">
        <div class="undangan">
          <div class="text">
            <span class="dari">Undangan dari</span>
            <span class="tanggal">{{ data.createdAt }}</span>
          </div>
          <div class="inviter">
            <b-avatar :size="17.5" rounded="lg"></b-avatar>
            <span class="email">{{ data.email }}</span>
          </div>
        </div>

        <div class="details">
          <div class="left">
            <span class="kap">{{ data.nama }}</span>
            <div class="role">
              <span>{{ data.jabatan }}</span>
            </div>
          </div>

          <div class="right">
            <b-button class="tolak" @click="reject(data)">tolak</b-button>
            <b-button class="terima" @click="accept(data)">terima</b-button>
          </div>
        </div>
      </b-card>
    </div>

    <div class="pagination">
      <b-pagination
        align="center"
        @change="onPageChanged"
        :total-rows="invitationsCard.length"
        :per-page="perPage"
        v-model="currentPage"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("apiInvite", ["invitationsCard", "loadingInvitation"]),
  },

  data() {
    return {
      list: [],
      paginatedItems: [],
      currentPage: 1,
      perPage: 4,
    };
  },

  beforeMount() {
    this.$store.dispatch("apiInvite/getInvite");
  },

  mounted() {
    let vm = this;
    vm.list = vm.invitationsCard;
    vm.paginatedItems = vm.invitationsCard;
    this.paginate(vm.perPage, 0);
  },

  methods: {
    interval() {
      setInterval(() => {
        this.$store.dispatch("apiInvite/getInvite");
      }, 10000);
    },
    async accept(data) {
      let x = localStorage.getItem("token");
      let role = data.jabatan;

      if (role == "manager") {
        await axios.post(
          "users/acceptKAP",
          {
            id: data.id,
            KAPId: data.KAPId,
          },
          {
            headers: {
              token: x,
            },
          }
        );
        this.$store.dispatch("apiInvite/getInvite");
        this.$store.dispatch("apiListKAP/getKAP");
      } else if (role == "supervisor") {
        await axios.post(
          "users/acceptClient",
          {
            id: data.id,
            KAPId: data.kapid,
          },
          {
            headers: {
              token: x,
            },
          }
        );
        this.$store.dispatch("apiInvite/getInvite");
        this.$store.dispatch("apiListKAP/getKAP");
      } else if (role == "auditor") {
        await axios.post(
          "users/acceptProject",
          {
            id: data.id,
            KAPId: data.kapid,
          },
          {
            headers: {
              token: x,
            },
          }
        );
        this.$store.dispatch("apiInvite/getInvite");
        this.$store.dispatch("apiListKAP/getKAP");
      }
    },

    async reject(data) {
      let role = data.jabatan;
      let x = localStorage.getItem("token");

      if (role == "manager") {
        await axios.post(
          "users/rejectKAP/" + data.id,
          {},
          {
            headers: {
              token: x,
            },
          }
        );
        this.$store.dispatch("apiInvite/getInvite");
        this.$store.dispatch("apiListKAP/getKAP");
      } else if (role == "supervisor") {
        await axios.post(
          "users/rejectClient/" + data.id,
          {},
          {
            headers: {
              token: x,
            },
          }
        );
        this.$store.dispatch("apiInvite/getInvite");
        this.$store.dispatch("apiListKAP/getKAP");
      } else if (role == "auditor") {
        await axios.post(
          "users/rejectProject/" + data.id,
          {},
          {
            headers: {
              token: x,
            },
          }
        );
        this.$store.dispatch("apiInvite/getInvite");
        this.$store.dispatch("apiListKAP/getKAP");
      }
    },

    paginate(pageSize, pageNumber) {
      let vm = this;
      vm.list.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
    },

    onPageChanged(page) {
      let vm = this;
      this.paginate(vm.perPage, page - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    transform: translateX(2%);
    width: 100%;
    height: 400px;
    .card {
      margin: 2% 4%;
      width: 40%;
      height: 172px;
      background-color: map-get($color, "white");
      border: none;
      .card-body {
        padding: 4% !important;
        .undangan {
          font-family: "nunito", sans-serif;
          width: 100%;
          border-bottom: 1px solid #eeeeee;
          padding-bottom: 2%;
          .text {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .dari {
              font-weight: 300;
              color: map-get($color, "gray-dark");
              font-size: 12px;
            }
            .tanggal {
              font-weight: 300;
              color: map-get($color, "gray-dark");
              font-size: 12px;
            }
          }

          .inviter {
            font-family: "nunito", sans-serif;
            font-size: 12px;
            font-weight: 300;
            color: map-get($color, "black");
            .email {
              margin-left: 4%;
            }
          }
        }

        .details {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 80%;
          .left {
            font-family: "nunito", sans-serif;
            display: flex;
            flex-direction: column;
            width: 50%;
            justify-content: space-between;
            .kap {
              color: map-get($color, "black");
              font-size: 17px;
              font-weight: 600;
            }
            .client {
              color: map-get($color, "black");
              font-size: 16px;
              font-weight: 300;
            }
            .role {
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 14%;
              span {
                background-color: map-get($color, "blue-light");
                padding: 0 10px;
                border-radius: 50px;
                text-transform: capitalize;
                color: map-get($color, "blue");
              }
            }
          }
          .right {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-color: map-get($color, "white");
            flex-direction: column;
            .btn {
              width: 95px;
              font-family: "nunito", sans-serif;
              font-weight: 700;
              text-transform: capitalize;
              border-radius: 50px;
              margin: 6px 0;
              height: 30px;
              padding: 0;
              text-align: center;
            }
            .tolak {
              background-color: map-get($color, "red");
              color: map-get($color, "white");
              border: none;
            }
            .terima {
              background-color: map-get($color, "green");
              color: map-get($color, "white");
              border: none;
            }
          }
        }
      }
    }
  }
  .pagination {
    width: 100%;
    margin-top: 1%;
  }
}
</style>
