<template>
  <b-modal
    id="modal-delete-KAP"
    title="OOPS!"
    hide-footer
    centered
    header-bg-variant="danger"
  >
    <p class="my-4">Harap Menghubungi SUPERADMIN</p>
  </b-modal>
</template>

<script>
export default {};
</script>
