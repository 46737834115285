<template>
  <div class="wrapper">
    <div class="filter">
      <div class="filter-wrapper">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Cari Bedasarkan Nama KAP"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <div class="table">
      <b-table
        hover
        :filter="filter"
        striped
        :items="stateKAP"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
        style="
          text-align: center;
          font-family: 'nunito', sans-serif;
          text-transform: capitalize;
        "
      >
        <template #cell(namaKAP)="data"
          ><a @click="goClient(data.item.clientid)">{{ data.value }}</a>
        </template>
        <template #cell(aksi)="data">
          <b-button
            pill
            @click="goClient(data.item.id, data.item.theRole)"
            style="
              background-color: #327ff2;
              font-weight: 700;
              border: none;
              font-family: 'nunito';
              margin-right: 2%;
            "
          >
            <img src="../../../../assets/icon/info.svg" alt="" />
            Masuk
          </b-button>
          <b-button
            pill
            v-b-modal.modal-delete-KAP
            style="
              background-color: #e0544e;
              border: none;
              font-family: 'nunito';
              font-weight: 700;
              margin-left: 2%;
            "
          >
            <img src="../../../../assets/icon/trash.svg" alt="" />
            Hapus</b-button
          >
        </template>
      </b-table>
    </div>

    <div class="paginate" v-if="stateKAP.length > 5">
      <b-pagination
        v-model="currentPage"
        :total-rows="stateKAP.length"
        style="width: 50%; font-family: 'nunito', sans-serif"
        :per-page="perPage"
        size="sm"
        align="center"
      ></b-pagination>
    </div>
    <modalDeleteKAP />
  </div>
</template>

<script>
import { mapState } from "vuex";
import modalDeleteKAP from "./ModalDelete.vue";

export default {
  components: { modalDeleteKAP },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      fields: [
        {
          key: "nomer",
          label: "No",
        },
        {
          key: "namaKAP",
          label: "Nama KAP",
        },
        {
          key: "theRole",
          label: "Peran",
        },
        {
          key: "aksi",
          label: "Aksi",
        },
      ],
      filter: null,
      sortDirection: "asc",
    };
  },
  computed: {
    ...mapState("apiListKAP", ["stateKAP"]),
  },

  methods: {
    goClient(id, role) {
      localStorage.setItem("role", role);
      this.$router.push("/profile_kap/" + id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  width: 100%;
  .filter {
    margin-bottom: 2%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .filter-wrapper {
      width: 60%;
    }
  }
  .table {
    width: 100%;
    height: 350px;
    .nameKAP {
      font-size: 20px !important;
    }
  }
  .paginate {
    position: sticky;
    margin-left: 32%;
  }
}
</style>
