<template>
  <div class="wrapper">
    <div class="title">
      <span>Project on Progress</span>
    </div>
    <div class="donut">
      <div class="angka">
        <div class="persen">{{ val }}</div>
        <div class="upah">{{ valLabels }}</div>
      </div>
      <apexchart
        type="donut"
        :series="series"
        :width="500"
        :options="chartOptions"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "@/ip_config";

export default {
  components: { apexcharts: VueApexCharts },

  data() {
    return {
      theChart: [],
      series: [0, 0, 0, 0, 0, 0],
      val: "",
      valLabels: "",
      chartOptions: {
        dataLabels: {
          style: {
            colors: ["#061737"],
          },
          dropShadow: {
            enabled: false,
          },
        },
        labels: [
          "Just Started",
          "Middle Process",
          "Almost Deadline",
          "Over Deadline",
          "Canceled",
          "Finish",
        ],
        colors: [
          "#6ADBBE",
          "#5C4AF5",
          "#F4B366",
          "#E0544E",
          "#696a6d",
          "#327ff2",
        ],
        chart: {
          id: "charts",
          type: "donut",
          events: {
            dataPointSelection: (a, b, config) => {
              let vm = this;
              if (config.dataPointIndex == 0) {
                vm.$router.push("/detail_donut/" + 0);
                vm.val = vm.series[0];
                vm.valLabels = vm.chartOptions.labels[0];
              } else if (config.dataPointIndex == 1) {
                vm.$router.push("/detail_donut/" + 1);
                vm.val = vm.series[1];
                vm.valLabels = vm.chartOptions.labels[1];
              } else if (config.dataPointIndex == 2) {
                vm.$router.push("/detail_donut/" + 2);
                vm.val = vm.series[2];
                vm.valLabels = vm.chartOptions.labels[2];
              } else if (config.dataPointIndex == 3) {
                vm.$router.push("/detail_donut/" + 3);
                vm.val = vm.series[3];
                vm.valLabels = vm.chartOptions.labels[3];
              } else if (config.dataPointIndex == 4) {
                vm.$router.push("/detail_donut/" + 4);
                vm.val = vm.series[4];
                vm.valLabels = vm.chartOptions.labels[4];
              } else if (config.dataPointIndex == 5) {
                vm.$router.push("/detail_donut/" + 5);
                vm.val = vm.series[5];
                vm.valLabels = vm.chartOptions.labels[5];
              }
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
            },
          },
        },
        legend: {
          show: false,
        },
      },
    };
  },

  mounted() {
    this.chart();
  },

  methods: {
    async chart() {
      let vm = this;
      let x = localStorage.getItem("token");
      let charts = await axios.get("KAP/chartByUsersLogin", {
        headers: { token: x },
      });
      let forSeries = [];
      charts.data.data.forEach((el) => {
        forSeries.push(Number(el.count));
      });

      vm.series = forSeries;
      vm.theChart = charts.data.data;

      vm.val = vm.series[2];
      vm.valLabels = vm.chartOptions.labels[2];
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  position: relative;
  padding: 1%;
  flex-direction: column;
  .title {
    font-family: "nunito", sans-serif;
    font-weight: 700;
    font-size: 36px;
    width: 100%;
    padding-left: 4%;
    display: flex;
    justify-content: center;
    padding-top: 2%;
  }
  .donut {
    font-family: "nunito", sans-serif;
    color: map-get($color, "color");
    width: 45%;
    display: flex;
    margin-top: 55px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .angka {
      position: absolute;
      .persen {
        font-size: 40px;
        font-weight: 700;
        text-align: center;
      }
      .upah {
        font-size: 14px;
        text-align: center;
        font-weight: 400;
      }
    }
  }
  .legend {
    margin-top: 68%;
    display: flex;
    flex-direction: column;
    font-family: "nunito", sans-serif;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    align-items: center;
    .top {
      display: flex;
      justify-content: space-evenly;
      width: 80%;
    }
    .bot {
      display: flex;
      width: 80%;
      justify-content: space-evenly;
      margin-top: 2%;
    }
    span {
      padding: 1% 0;
      letter-spacing: 1px;
    }
  }
}
</style>
