<template>
  <div class="wrapper">
    <div class="line"></div>
    <apexcharts
      :height="390"
      type="line"
      :series="series"
      :options="chartOptions"
    >
    </apexcharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "@/ip_config";
import moment from "moment";
moment.locale("id");
export default {
  components: { apexcharts: VueApexCharts },
  props: ["toChild"],
  data() {
    return {
      tahun: "",
      series: [
        {
          name: "Just Started",
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        {
          name: "Finished",
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        {
          name: "40%",
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        {
          name: "60%",
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        {
          name: "80%",
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        {
          name: "Almost Deadline",
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
      ],
      chartOptions: {
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 7,
        },
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "April",
          "Mei",
          "Jun",
          "Jul",
          "Agust",
          "Sept",
          "Oct",
          "Nov",
          "Des",
        ],
        colors: [
          "#6ADBBE",
          "#5C4AF5",
          "#F4B366",
          "#E0544E",
          "#696a6d",
          "#59E1FF",
        ],
        chart: {
          id: "charts",
          type: "line",
        },
        legend: {
          show: true,
        },
      },
    };
  },
  watch: {
    toChild: function(newVal, oldVal) {
      if (newVal != oldVal) {
        let str = newVal.toString();
        if (str.length == 4) {
          this.tahun = Number(str);
          this.getData();
        }
      }
    },
  },
  beforeMount() {
    this.getYear();
  },
  mounted() {},
  methods: {
    getYear() {
      let vm = this;
      var dateNow = new Date();
      var year = moment(dateNow).format("YYYY");
      vm.tahun = year;
      if (vm.tahun) {
        this.getData();
      }
      vm.$emit("tahun", vm.tahun);
    },
    async getData() {
      let vm = this;

      let chart = await axios.get("tabelLinear/listByTahun/" + vm.tahun, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      if (chart.data.message == "sukses") {
        let start = chart.data.data.map((item) => {
          return item.bawah20;
        });
        let mid = chart.data.data.map((item) => {
          return item.bawah40;
        });
        let overMid = chart.data.data.map((item) => {
          return item.bawah60;
        });
        let over = chart.data.data.map((item) => {
          return item.bawah80;
        });
        let almost = chart.data.data.map((item) => {
          return item.bawah99;
        });
        let finish = chart.data.data.map((item) => {
          return item.selesai;
        });

        vm.series = [
          {
            data: start,
          },
          {
            data: finish,
          },
          {
            data: mid,
          },
          {
            data: overMid,
          },
          {
            data: over,
          },
          {
            data: almost,
          },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
